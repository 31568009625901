import React from "react";
import Card from "./Card";

function ChargingStations() {
  const stationsData = {
    twoPorts: {
      name: "2-ports Charging Station",
      image: "/images/station1.jpg",
    },
    homeCharger: {
      name: "Home Charging Mini",
      image: "/images/station2.jpg",
    },
    publicCharger: {
      name: "Public Charger",
      image: "/images/station3.jpg",
    },
  };
  return (
    <div className="chargingStations ">
      <div className="container gap-4  py-4  h-100 contentBox">
        <div className="row  align-items-center justify-content-center">
          <div className="col-md-6 bg-primar py-2 d-flex flex-column align-items-center justify-content-center">
            {/* <h6 className=" fw-light text-center">Online Store</h6> */}
            <h2 className="fw-bolder lh-lg text-center">
              Popular Charging <span className=" text-primary"> Stations</span>
            </h2>
          </div>
        </div>
        <div className="row">
          <Card data={stationsData.twoPorts} />
          <Card data={stationsData.homeCharger} />
          <Card data={stationsData.publicCharger} />
        </div>
      </div>
    </div>
  );
}

export default ChargingStations;
