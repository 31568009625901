import React from "react";

function HomeBanner() {
  return (
    <>
      {" "}
      <div className="">
        <div className="container  homeContainer ">
          <div class="row w-100 ">
            <div className="col-md-6 gap-4   d-grid py-4">
              <div className="row">
                <div className="col-md-6 ">
                  <img src="/images/Artboard 2.png" className="w-75" />
                </div>

                <div className="px-4">
                  {" "}
                  <span>Travel Farther, Charge anytime</span>
                </div>
              </div>
              <div className="row  justify-content-between mb-2">
                <div className="  col-6 p-2 ">
                  <div className="card w-100 p-2 h-100 d-flex  align-items-center justify-content-end">
                    <div className="row d-flex ">
                      <div className="col-md-6   d-none d-md-block ">
                        <img src="/images/top1-1.png" className="w-75" />
                      </div>
                      <div className="col-md-6  ">
                        <img src="/images/top1-2.png" className="w-75" />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <span className="text-center card-text-size fw-bold">
                        Convenient EV Charging <br />
                        At your Apartment Our Office
                      </span>
                    </div>
                  </div>
                </div>
                <div className="  col-6 p-2 ">
                  <div className="card w-100 p-2 h-100 d-flex  align-items-center justify-content-end">
                    <img
                      src="/images/top-2.png"
                      alt="card-img"
                      className="img-fluid w-md-75"
                    />
                    <div className="d-flex justify-content-center">
                      <span className="text-center card-text-size fw-bold">
                        Effortlessly deploy and manage <br />
                        commercial EV charging
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border p-2 justify-content-center box">
                <div className="col-md-12 d-flex align-items-end justify-content-center">
                  <span className="text-center  fw-bold">
                    EV charges installed for $0 down
                  </span>
                </div>

                <div className="col-4 p-2 car flex-column gap-2 text-center d-flex align-items-center  justify-content-center">
                  <img
                    src="/images/bottomCard1.png"
                    alt="card-image"
                    className="img-fluid w-50"
                  />
                  <div className="d-flex justify-content-center">
                    <span className="text-center card-text-size fw-bold">
                      You Pay, You Own
                    </span>
                  </div>
                </div>
                <div className="col-4 p-2 car flex-column gap-2 text-center d-flex align-items-center  justify-content-center">
                  <img
                    src="/images/bottomCard2.png"
                    alt="card-image"
                    className="img-fluid w-50"
                  />
                  <div className="d-flex justify-content-center">
                    <span className="text-center card-text-size fw-bold">
                      Lease To Own
                    </span>
                  </div>
                </div>
                <div className="col-4 p-2 car flex-column gap-2 text-center d-flex align-items-center  justify-content-center">
                  <img
                    src="/images/bottomCard3.png"
                    alt="card-image"
                    className="img-fluid w-50"
                  />
                  <div className="d-flex justify-content-center">
                    <span className="text-center card-text-size fw-bold">
                      Travolts Pays, Travolts Own
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6  d-flex align-items-center justify-content-end">
              {" "}
              <img
                src="/images/TRAVOLTS APP.webp"
                alt="phone-image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBanner;
