import "./assets/scss/styles.scss";
import HomeBanner from "./components/banner/HomeBanner";
import ChargingStations from "./components/chargingStations";

function App() {
  return (
    <>
      <HomeBanner />
      <ChargingStations />
    </>
  );
}

export default App;
