import React from "react";

function Card({ data }) {
  return (
    <div className="col-md-4 col-6 col-md-4 p-2">
      <div className="w-100 h-100 card p-2">
        <img src={data.image} alt="" className="img-fluid" />
        <div className=" d-flex align-items-center justify-content-center">
          <p className="text-center fw-light text-sm">{data.name}</p>
        </div>
      </div>
    </div>
  );
}

export default Card;
